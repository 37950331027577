import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/normalize.css'
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App/>
)


reportWebVitals();
